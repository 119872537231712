import { defineMessage } from '@lingui/macro';

import DateRangePicker from '../../../generic/form/DateRangePicker';
import InputGoogleMapsPlace from '../../../generic/form/InputGoogleMapsPlace';
import InputNumber from '../../../generic/form/InputNumber';
import InputText from '../../../generic/form/InputText';
import Select from '../../../generic/form/Select';
import SelectMultipleChoices from '../../../generic/form/SelectMultipleChoices';

// ==============================================================================================
// This file lists all available filters
// The PRODUCTS_FILTERS uses props from the Select/Input components
// ==============================================================================================

export const PRODUCTS_FILTERS = {
  // This is the value that we will pass in an array prop of ProductFilter
  TAGS: {
    // This is the class type
    type: Select,
    // The field name : MUST BE UNIQUE
    fieldName: 'tag',
    // The visible label (the Input components can also have a fieldPlaceholder)
    fieldLabel: defineMessage({ message: 'Category' }),
    // The default value, MUST BE SET (even if empty)
    default: '',
    // For a select component, we have a list of choices, this one is dynamic and will be filled later
    fieldChoices: [],
    // For more information just look at the props of the Select/Input components
  },
  SUBCATEGORIES: {
    type: Select,
    fieldName: 'subcategory',
    fieldLabel: defineMessage({ message: 'Category' }),
    default: '',
    fieldChoices: [],
  },
  SUBCATEGORIES_MULTICHOICE: {
    type: SelectMultipleChoices,
    fieldName: 'subcategory',
    fieldLabel: defineMessage({ message: 'Categories' }),
    default: '',
    fieldChoices: [],
    maxValuesToShow: 1,
  },
  DATE: {
    type: DateRangePicker,
    fieldName: 'dateRange',
    fieldLabel: defineMessage({ message: 'Date' }),
    fieldPlaceholder: defineMessage({ message: 'Choose a date' }),
  },
  TITLE: {
    type: InputText,
    fieldName: 'title',
    fieldLabel: defineMessage({ message: 'Search' }),
    fieldPlaceholder: defineMessage({ message: 'Activity name' }),
    default: '',
  },
  NB_TICKETS: {
    type: InputNumber,
    fieldName: 'nb_tickets',
    fieldLabel: defineMessage({ message: 'Participants' }),
    fieldPlaceholder: '-',
    default: '',
    fieldMinimum: 1,
    maxWidth: '90px',
  },
  PROVIDER: {
    type: Select,
    fieldName: 'provider',
    fieldLabel: defineMessage({ message: 'Provider' }),
    default: '',
    fieldChoices: [], // dynamically filled
    minElementsToDisplayFilter: 2,
    triggerChangeOnUpdate: true,
    maxWidth: '180px',
  },
  LOCATION: {
    type: InputGoogleMapsPlace,
    fieldName: 'location',
    fieldLabel: defineMessage({ message: 'Location' }),
    fieldPlaceholder: defineMessage({ message: 'Activity city' }),
    default: '',
  },
  STAFF: {
    type: Select,
    fieldName: 'staff',
    fieldLabel: defineMessage({ message: 'Staff' }),
    default: '',
    fieldChoices: [], // dynamically filled
    maxWidth: '180px',
  },
};
