import { useContext } from 'react';

import { useEffectOnce } from 'usehooks-ts';

import { PRODUCTS_FILTERS } from '../components/business/product/list/ProductsListFilters';
import { CoreContext } from '../context/CoreContext';
import useReadProductTeams from './useReadProductTeams';
import useReadStaffChoices from './useReadStaffChoices';
import useReadSubcategories from './useReadSubcategories';
import useReadTags from './useReadTags';

const PRODUCT_FILTER_TYPE_BYTAG = 1;

const useReadProductFilters = () => {
  const { coreData } = useContext(CoreContext);
  const { product_tags: productTags, product_teams: productTeams, subcategories, staffs, staff_filter_forced: isStaffFilterForced } = coreData;
  const {
    voucher_only: isModeBcOnly,
    show_providers: showProductTeams,
    show_locations: showLocationFilter,
    show_staffs: showStaffFilter,
    product_filter_type: productFilterType,
  } = coreData.booking_engine;

  const countProductTeams = productTeams ? Object.keys(productTeams).length : 0;
  const choicesTag = useReadTags(productFilterType === PRODUCT_FILTER_TYPE_BYTAG ? productTags : null);
  const choicesSubcat = useReadSubcategories(productFilterType !== PRODUCT_FILTER_TYPE_BYTAG ? subcategories : null);
  const choicesProductTeam = useReadProductTeams(showProductTeams ? productTeams : null);
  const choicesStaffFilter = useReadStaffChoices(showStaffFilter ? staffs : null);

  // We load the dynamic filters
  useEffectOnce(() => {
    PRODUCTS_FILTERS.TAGS.fieldChoices = choicesTag;
    PRODUCTS_FILTERS.SUBCATEGORIES.fieldChoices = choicesSubcat;
    PRODUCTS_FILTERS.SUBCATEGORIES_MULTICHOICE.fieldChoices = choicesSubcat;
    PRODUCTS_FILTERS.PROVIDER.fieldChoices = choicesProductTeam;
    PRODUCTS_FILTERS.STAFF.fieldChoices = choicesStaffFilter;
    if (isStaffFilterForced) {
      PRODUCTS_FILTERS.STAFF.isDisabled = true;
    }
  });

  // SUBCATEGORIES_MULTICHOICE is replacing SUBCATEGORIES for all BEs
  // we keep SUBCATEGORIES in case
  const getTagOrCategoryFilter = () => {
    // Legacy, kept for save
    // if (isArrEmpty(productTags) || countProductTeams > 1) {
    //   return PRODUCTS_FILTERS.SUBCATEGORIES;
    // }
    return productFilterType === PRODUCT_FILTER_TYPE_BYTAG ? PRODUCTS_FILTERS.TAGS : PRODUCTS_FILTERS.SUBCATEGORIES_MULTICHOICE;
  };

  // we have a specific order
  const getFiltersToDisplay = () => {
    const filtersToDisplay = [];

    if (showStaffFilter) {
      filtersToDisplay.push(PRODUCTS_FILTERS.STAFF);
    }

    if (showLocationFilter) {
      filtersToDisplay.push(PRODUCTS_FILTERS.LOCATION);
    }

    filtersToDisplay.push(getTagOrCategoryFilter());

    if (!isModeBcOnly) {
      filtersToDisplay.push(PRODUCTS_FILTERS.DATE);
    }

    filtersToDisplay.push(PRODUCTS_FILTERS.TITLE);

    if (showProductTeams && countProductTeams >= PRODUCTS_FILTERS.PROVIDER.minElementsToDisplayFilter) {
      filtersToDisplay.push(PRODUCTS_FILTERS.PROVIDER);
    }
    return filtersToDisplay;
  };

  return getFiltersToDisplay();
};

export default useReadProductFilters;
