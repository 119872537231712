import useIsWidthAbove from './useIsWidthAbove';
import useIsWidthBelow from './useIsWidthBelow';

const useIsTablet = () => {
  const isTablet = useIsWidthAbove(768) && useIsWidthBelow(992);

  return isTablet;
};

export default useIsTablet;
