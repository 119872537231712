import { useEffect, useState } from 'react';

import cloneDeep from 'lodash.clonedeep';

import { DATERANGE_FILTER_NAME_END, DATERANGE_FILTER_NAME_START, DATE_FILTER_NAME } from '../components/generic/form/DateRangePicker';
import { isDatePickerFilter, isDateRangePickerFilter } from '../utils/dateFilterUtil';
import { isSameDayOfDates } from '../utils/dateUtil';
import useReadFilters from './useReadFilters';

const useWriteFilters = (filtersToDisplay, displayLimit, fetchNewFilterData) => {
  const { filters, setFilters, debouncedFilters, savedFilters, setSavedFilters } = useReadFilters(filtersToDisplay, displayLimit);
  const [canResetFilters, setCanResetFilters] = useState(false);

  // this is triggered at every new render...
  useEffect(() => {
    if (!debouncedFilters) return;

    fetchNewFilterData(debouncedFilters);
    setSavedFilters({ ...savedFilters, ...debouncedFilters, offset: '-', limit: '-' });
    setCanResetFilters(checkCanResetFilters());

    // ...but ONLY for the initial page render and when the debouncedFilters's state changes
    // because we used the array dependency at the end
    // and that's ok because we want to change the state only when a filter has changed (and at page init)
  }, [JSON.stringify(debouncedFilters)]);

  const onFilterChange = (fieldName, newValue) => {
    const newFilters = cloneDeep(filters);
    newFilters[fieldName] = newValue;
    if (fieldName === DATE_FILTER_NAME) {
      newFilters[DATERANGE_FILTER_NAME_START] = newValue;
    }
    setFilters(newFilters);
  };

  const onFilterDateRangeChange = (startDate, endDate) => {
    const newFilters = cloneDeep(filters);
    newFilters[DATERANGE_FILTER_NAME_START] = startDate;
    newFilters[DATE_FILTER_NAME] = startDate;
    newFilters[DATERANGE_FILTER_NAME_END] = endDate;
    setFilters(newFilters);
  };

  const checkCanResetFilters = () => {
    for (const filterObject of filtersToDisplay) {
      if (filterObject.isDisabled) continue;

      const filterValue = debouncedFilters[filterObject.fieldName];
      const filterDefault = filterObject.default;

      // DateRange: can reset if start or end set
      if (isDateRangePickerFilter(filterObject.type)) {
        const filterDateStart = debouncedFilters[DATERANGE_FILTER_NAME_START];
        if (filterDateStart != null && filterDateStart != '') return true;

        const filterDateEnd = debouncedFilters[DATERANGE_FILTER_NAME_END];
        if (filterDateEnd != null && filterDateEnd != '') return true;
      }
      // Date: compare the day
      else if (isDatePickerFilter(filterObject.type)) {
        if (filterDefault === undefined && filterValue === filterDefault) continue;
        if (!isSameDayOfDates(filterValue, filterDefault)) return true;
        continue;
      }

      // Others: simple compare, no triple !==
      const diffValue = filterValue != filterDefault;
      if (diffValue) return true;
    }
    return false;
  };

  const resetFiltersToDefault = () => {
    const newFilters = {
      limit: debouncedFilters['limit'],
      offset: 0,
    };

    for (const filterObject of filtersToDisplay) {
      // keep current value when disabled (filter is forced)
      if (filterObject.isDisabled) {
        newFilters[filterObject.fieldName] = debouncedFilters[filterObject.fieldName];
        continue;
      }

      if (isDateRangePickerFilter(filterObject.type)) {
        newFilters[DATERANGE_FILTER_NAME_START] = null;
        newFilters[DATERANGE_FILTER_NAME_END] = null;
      } else {
        newFilters[filterObject.fieldName] = filterObject.default;
      }
    }
    setFilters(newFilters);
  };

  return { filters, onFilterChange, onFilterDateRangeChange, canResetFilters, resetFiltersToDefault };
};

export default useWriteFilters;
