import React, { useRef, useState } from 'react';

import { Flex, Progress } from '@chakra-ui/react';

import useFetch from '../../hooks/useFetch';
import { containsLatLng } from '../../utils/mapsUtil';
import { addQueryParamToUrl, jsonToQueryParams, REQUEST_STATUS } from '../../utils/requestUtil';
import { isStrNullOrEmpty } from '../../utils/strUtil';
import { BOTTOM_ROUNDED_CONTAINER_PROPS } from '../../utils/styleUtil';
import FetchLoaderSkeleton from './FetchLoaderSkeleton';
import FilterBar from './FilterBar';
import FilteredListEmptyError from './FilteredListEmptyError';
import { LOCATION_FILTER_NAME } from './form/InputGoogleMapsPlace';
import ContainerLoadMore, { scrollTopContainerLoadMore } from './layout/ContainerLoadMore';

// ==============================================================================================
// This component displays a "listType" component with its filter bar containing "filtersToDisplay"
// It fetches "itemsPerScroll" elements per query on "endpoint"
// ==============================================================================================

const FilteredList = ({
  listType,
  endpoint,
  propertyToReturn,
  itemsPerScroll,
  filtersToDisplay,
  filtersPerLine,
  skeletonHeight,
  showFiltersMobile,
  ...props
}) => {
  // Our url is a state so when we change it, the useFetch (useEffect inside) will be triggered again
  const [fetchUrl, setFetchUrl] = useState(null);
  const replaceFetchData = useRef(true);
  const { data, hasMore, status } = useFetch(fetchUrl, propertyToReturn, replaceFetchData, itemsPerScroll);

  const fetchData = (replaceData = true) => {
    replaceFetchData.current = replaceData;
    const newUrl = addQueryParamToUrl(endpoint, jsonToQueryParams(filters.current));
    setFetchUrl(newUrl);
  };

  // We store the filters as a ref because we dont want a re-render yet
  // Also we store the filters here because on scroll down, we update the offset value
  const filters = useRef({});

  const filtersCanFetchData = () => {
    if (filters.current == null) return true;

    // If there's a LOCATION filter but it's not formatted to lat/lng yet then we dont trigger the fetch
    // It will be passed to gmaps api to retrieve the lat/lng and then the fetch will be triggered
    const locFilter = filters.current[LOCATION_FILTER_NAME];
    if (isStrNullOrEmpty(locFilter)) return true;
    return containsLatLng(locFilter);
  };

  // This is called by the FilterBar when a filter changes
  // So we fetch the new data, replacing the existing data
  const fetchNewFilterData = newFilters => {
    // console.log('new filters:', newFilters);
    filters.current = newFilters;
    if (!filtersCanFetchData()) return;

    fetchData();
    scrollTopContainerLoadMore();
  };

  // This is called by the ContainerLoadMore until there is no more data
  // Because when there is no more data, we don't give this prop
  // So we fetch the new data, appending to the existing data
  const handleLoadMore = () => {
    const fetchFilters = Object.assign({}, filters.current, {
      offset: filters.current.offset + itemsPerScroll,
    });
    filters.current = fetchFilters;
    fetchData(false);
  };

  return (
    <>
      <FilterBar
        filtersToDisplay={filtersToDisplay}
        fetchNewFilterData={fetchNewFilterData}
        displayLimit={itemsPerScroll}
        showFiltersMobile={showFiltersMobile}
        filtersPerLine={filtersPerLine}
      >
        {props.children}
      </FilterBar>
      {status === REQUEST_STATUS.LOADING && <Progress size="xs" isIndeterminate />}

      <Flex flexDirection="column" bg="brand.background_secondary" p="8px" {...BOTTOM_ROUNDED_CONTAINER_PROPS}>
        {!data && <FetchLoaderSkeleton status={status} skeletonsNumber={3} skeletonHeight={skeletonHeight} />}

        {data && data.length > 0 && (
          <ContainerLoadMore onLoadMore={hasMore && handleLoadMore} status={status}>
            {React.createElement(listType, { data: data })}
          </ContainerLoadMore>
        )}

        {data && data.length <= 0 && <FilteredListEmptyError listType={listType} />}
      </Flex>
    </>
  );
};

export default FilteredList;
