import React, { useRef, useState } from 'react';
import '@fontsource/inter/variable.css';

import { ChakraProvider } from '@chakra-ui/react';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { I18nProvider } from '@lingui/react';
import { useEffectOnce, useFetch } from 'usehooks-ts';

import AppContainer from './AppContainer';
import AppRouting from './AppRouting';
import AppInitError from './components/generic/error/AppInitError';
import { apiGetSettings, COLOR_FALLBACK, GTM_ID_VAKARIO } from './constants';
import { CoreContextProvider } from './context/CoreContext';
import { ExchangeContextProvider } from './context/ExchangeContext';
import { loadGoogleTagManager } from './gtm';
import useDevelopmentTheme from './hooks/useDevelopmentTheme';
import { devMessage } from './settings/settings';
import { getThemeFromColors } from './themes/theme';
import { initTranslations } from './utils/langUtil';
import { getFullApiUrl } from './utils/navigationUtil';
import { getQueryParam } from './utils/requestUtil';
import { getValidUuidOrNull } from './utils/strUtil';

function App() {
  const initDone = useRef(false);
  const storageDenied = useRef(false);
  const userTheme = useRef(null);

  // This useFetch is called before anything else
  // It uses the hooksTS hook because our custom hook does custom things we dont need here
  const [fetchUrl, setFetchUrl] = useState(null);
  const { data, error } = useFetch(fetchUrl, { credentials: 'include' });

  const { getDevThemeColors, getDevThemeComponent } = useDevelopmentTheme();

  useEffectOnce(() => {
    let settingsUrl = getFullApiUrl(apiGetSettings());

    // If a staff is forced in the BE's url, the backend settings will only give this staff to us
    const forcedStaffId = getValidUuidOrNull(getQueryParam('staff'));
    if (forcedStaffId) {
      settingsUrl += '?staff_id=' + forcedStaffId;
    }

    setFetchUrl(settingsUrl);
  });

  // This cannot go in an effect because we want the translations before everything
  // Also because to init the translations we might need the backend core data in the future
  const runCoreInit = () => {
    if (!data) return false;
    if (initDone.current) return true;

    initDone.current = true;
    checkStorageAccess();

    loadTheme();
    initTranslations(data.booking_engine.forced_lang);
    devMessage('Loaded settings from backend:', data);

    loadGoogleTagManager(window, document, 'script', 'dataLayerYoplanning', GTM_ID_VAKARIO);
    if (data.booking_engine.team.gtm_id) {
      loadGoogleTagManager(window, document, 'script', 'dataLayer', data.booking_engine.team.gtm_id);
    }
    return true;
  };

  // When blocking 3rd party cookies, the local storage is denied
  const checkStorageAccess = () => {
    try {
      localStorage.getItem('PANIK');
    } catch (err) {
      storageDenied.current = true;
    }

    // fuck safari
    // if (!document.hasStorageAccess) return;

    // document.hasStorageAccess().then(hasAccess => {
    //   console.log('hasAccess', hasAccess);
    //   if (!hasAccess) {
    //     storageDenied.current = true;
    //   }
    // });

    // if (!storageDenied.current && !getCookie(CSRF_COOKIE_NAME)) {
    //   storageDenied.current = true;
    // }
  };

  const loadTheme = () => {
    const uiSettings = data.booking_engine.ui_settings;
    const themeColors = uiSettings && uiSettings.colors ? uiSettings.colors : COLOR_FALLBACK;
    const devThemeColors = storageDenied.current ? null : getDevThemeColors(data.can_customise, themeColors);
    userTheme.current = getThemeFromColors(devThemeColors ?? themeColors);
  };

  const hasLoadError = () => {
    // not loaded yet
    if (!data) return false;

    const manufacturerError = data.is_manufacturer && data.user === null;
    return storageDenied.current || data.booking_engine.team.online_selling === false || manufacturerError;
  };

  return (
    <>
      {/* This is when data is not loaded yet, we dont have translation or theme here 
      If an error occurs and we cant get the data...we dont have translation*/}
      {!data && (
        <ChakraProvider>
          <AppInitError hasError={error}>{'An error occured while accessing team data.'}</AppInitError>
        </ChakraProvider>
      )}

      {runCoreInit() && !hasLoadError() && userTheme.current && (
        <ChakraProvider theme={userTheme.current}>
          <CoreContextProvider data={data}>
            <ExchangeContextProvider data={{}}>
              <I18nProvider i18n={i18n}>
                {getDevThemeComponent(data.can_customise, userTheme.current.colors.brand)}
                <AppContainer loggedUser={data.user}>
                  <AppRouting hasProducts={data.nb_products > 0} hasShopResources={data.nb_resources > 0} shopOnly={data.booking_engine.shop_only} />
                </AppContainer>
              </I18nProvider>
            </ExchangeContextProvider>
          </CoreContextProvider>
        </ChakraProvider>
      )}

      {hasLoadError() && userTheme.current && (
        <ChakraProvider theme={userTheme.current}>
          <I18nProvider i18n={i18n}>
            <AppInitError hasError>
              {storageDenied.current && <Trans>Your browser blocks 3rd party cookies. They are needed for this application.</Trans>}
              {!data.booking_engine.team.online_selling && <Trans>Online selling is disabled, please contact an administrator.</Trans>}
              {data.is_manufacturer && data.user === null && <Trans>This booking engine can only be used while logged in to Yoplanning.</Trans>}
            </AppInitError>
          </I18nProvider>
        </ChakraProvider>
      )}
    </>
  );
}

export default App;
