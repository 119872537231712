import { Flex, Text, VStack } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { useEffectOnce } from 'usehooks-ts';

import ButtonLink from '../button/ButtonLink';

const TOAST_ID = 'errorToastId';

const ErrorFallback = ({ error }) => {
  const toast = useToast();

  useEffectOnce(() => {
    if (!error) return;

    if (error.toString() === 'Error: MISSING_PRICE_VARIATION') {
      localStorage.clear();

      if (toast.isActive(TOAST_ID)) return;

      toast({
        id: TOAST_ID,
        title: t`Products of your cart have changed, please try again.`,
        status: 'error',
        duration: 4000,
        variant: 'subtle',
      });
    }
  });

  return (
    <Flex justifyContent="center" mt="4rem">
      <VStack>
        <Text>
          <Trans>Oops! Something went wrong.</Trans>
        </Text>

        <ButtonLink
          onClick={() => {
            localStorage.clear();
            location.reload();
          }}
        >
          <Trans>Back to home</Trans>
        </ButtonLink>
      </VStack>
    </Flex>
  );
};

export default ErrorFallback;
