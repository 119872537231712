import { t } from '@lingui/macro';

const useReadStaffChoices = staffs => {
  const all = t({
    id: 'ALL_MASCULINE',
    message: 'All',
  });

  const choices = [{ label: all, value: '' }];
  if (!staffs) {
    return choices;
  }

  for (const staff of staffs) {
    choices.push({ label: staff.first_name.trim() + ' ' + staff.last_name.trim(), value: staff.user_id });
  }

  return choices;
};

export default useReadStaffChoices;
