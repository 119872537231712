import { defineMessage } from '@lingui/macro';

import { getFilterHourArray } from '../../../../utils/dateUtil';
import DatePickerAvailabilities from '../../../generic/form/DatePickerAvailabilities';
import { DATE_FILTER_NAME } from '../../../generic/form/DateRangePicker';
import InputNumber from '../../../generic/form/InputNumber';
import Select from '../../../generic/form/Select';

// ==============================================================================================
// This file lists all available filters
// The AVAILABILITIES_FILTERS uses props from the Select/Input components
// ==============================================================================================

// The hour filter will start from 6h => 23h => 0h => 5h
const AVAILIBILITY_HOUR_FILTER_START = 6;
// The spacing between hours we display
const AVAILABILITIES_HOUR_STEP = 1;

export const AVAILABILITIES_FILTERS = {
  DATE: {
    type: DatePickerAvailabilities,
    fieldName: DATE_FILTER_NAME,
    fieldLabel: defineMessage({ message: 'Date' }),
    fieldPlaceholder: defineMessage({ message: 'Choose a date' }),
    minWidth: { base: '115px', lg: '130px' },
    maxWidth: '140px',
  },
  HOUR: {
    type: Select,
    fieldName: 'hour',
    fieldLabel: defineMessage({ message: 'Hour' }),
    default: '',
    fieldChoices: getFilterHourArray(AVAILIBILITY_HOUR_FILTER_START, AVAILABILITIES_HOUR_STEP),
    maxWidth: { base: '120px', md: '85px' },
    hiddenBehindShowMore: true,
  },
  NB_TICKETS: {
    type: InputNumber,
    fieldName: 'nb_tickets',
    fieldLabel: defineMessage({ message: 'Participants' }),
    default: 1,
    fieldMinimum: 1,
    maxWidth: { base: '120px', md: '100px' },
    hiddenBehindShowMore: true,
  },
  STAFF: {
    type: Select,
    fieldName: 'staff',
    fieldLabel: defineMessage({ message: 'Staff' }),
    default: '',
    fieldChoices: [],
    maxWidth: '180px',
  },
};
