import { useContext } from 'react';

import { useEffectOnce } from 'usehooks-ts';

import { AVAILABILITIES_FILTERS } from '../components/business/availability/list/AvailabilitiesListFilters';
import { CoreContext } from '../context/CoreContext';
import useReadStaffChoices from './useReadStaffChoices';

const useReadAvailabilityFilters = () => {
  const { coreData } = useContext(CoreContext);
  const { staffs, staff_filter_forced: isStaffFilterForced } = coreData;
  const { show_staffs: showStaffFilter } = coreData.booking_engine;

  const choicesStaffFilter = useReadStaffChoices(showStaffFilter ? staffs : null);

  // We load the dynamic filters
  useEffectOnce(() => {
    AVAILABILITIES_FILTERS.STAFF.fieldChoices = choicesStaffFilter;
    if (isStaffFilterForced) {
      AVAILABILITIES_FILTERS.STAFF.isDisabled = true;
    }
  });

  // we have a specific order
  const getFiltersToDisplay = () => {
    const filtersToDisplay = [AVAILABILITIES_FILTERS.DATE];

    if (showStaffFilter) {
      filtersToDisplay.push(AVAILABILITIES_FILTERS.STAFF);
    }

    filtersToDisplay.push(AVAILABILITIES_FILTERS.HOUR);
    filtersToDisplay.push(AVAILABILITIES_FILTERS.NB_TICKETS);

    return filtersToDisplay;
  };

  return getFiltersToDisplay();
};

export default useReadAvailabilityFilters;
