import { Link, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { FaMapMarkerAlt } from 'react-icons/fa';

import IconWithText from '../../../generic/IconWithText';

const ProductDetailPlace = ({ place, iconProps }) => {
  return (
    <IconWithText icon={FaMapMarkerAlt} iconProps={iconProps}>
      <>
        <Text>{place.formatted_address}</Text>

        {hasValidCoordinates(place) && (
          <Link href={getMapsLink(place)} isExternal textAlign="right">
            <Trans>View on map</Trans>
          </Link>
        )}
      </>
    </IconWithText>
  );
};

export default ProductDetailPlace;

const hasValidCoordinates = place => {
  return place.location && place.location.coordinates && place.location.coordinates.length === 2;
};

const getMapsLink = place => {
  const latitude = place.location.coordinates[0];
  const longitude = place.location.coordinates[1];
  return `https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude}`; // www is very important, otherwise gmaps will not open properly on mobile!

  // return `https://maps.google.com/?ll=${latitude},${longitude}`; // This url does not display map marker!
};
