import { useContext } from 'react';

import { AVAILABILITIES_FILTERS } from '../components/business/availability/list/AvailabilitiesListFilters';
import { CoreContext } from '../context/CoreContext';
import { findArrayItemByKey } from '../utils/arrayUtil';
import useReadFilterValue from './useReadFilterValue';

const useReadSelectedStaffFilterValue = () => {
  const { coreData } = useContext(CoreContext);
  const { staffs, staff_filter_forced: isStaffFilterForced } = coreData;
  const { show_staffs: showStaffFilter } = coreData.booking_engine;

  const staffFilterValue = useReadFilterValue(AVAILABILITIES_FILTERS.STAFF);

  const selectedStaff = showStaffFilter && staffFilterValue ? findArrayItemByKey('user_id', staffFilterValue, staffs) : null;

  return { selectedStaff, isStaffFilterForced };
};

export default useReadSelectedStaffFilterValue;
