import { useRef } from 'react';

import { HStack, Icon, Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Text, useDisclosure } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import useReadSelectedStaffFilterValue from '../../../hooks/useReadSelectedStaffFilterValue';
import ButtonIcon from '../../generic/button/ButtonIcon';

const SessionVariantSelectedStaff = () => {
  const ref = useRef();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const { selectedStaff, isStaffFilterForced } = useReadSelectedStaffFilterValue();

  if (!selectedStaff) return <></>;

  return (
    <HStack gap={0}>
      <Text fontSize="sm">Staff: {selectedStaff.first_name + ' ' + selectedStaff.last_name}</Text>

      {!isStaffFilterForced && (
        <Popover isOpen={isOpen} onClose={onClose}>
          <PopoverTrigger>
            <ButtonIcon ref={ref} icon={<Icon as={AiOutlineQuestionCircle} boxSize="20px" />} onClick={onToggle} />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverCloseButton />
            <PopoverBody>
              <Text fontSize="xs" p={2}>
                <Trans>To select another staff, go back to the previous page.</Trans>
              </Text>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </HStack>
  );
};

export default SessionVariantSelectedStaff;
