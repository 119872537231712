import { forwardRef, useEffect, useRef } from 'react';

import { FormControl } from '@chakra-ui/react';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import DatePicker from 'react-datepicker';

import { ChakraDateInput } from '../../../utils/dateUtil';
import InputFormLabel from './InputFormLabel';

import 'react-datepicker/dist/react-datepicker.css';

const DatepickerCommon = (
  {
    isMobile,
    displayLabel = true,
    fieldName,
    fieldLabel,
    isRequired,
    fieldPlaceholder,
    displayToday,
    dateFormat = 'dd/MM/yyyy',
    minWidth,
    maxWidth,
    ...props
  },
  ref
) => {
  const pickerRef = ref || useRef(null);

  // Disable keyboard on mobile
  useEffect(() => {
    if (isMobile && pickerRef.current !== null) {
      pickerRef.current.input.readOnly = true;
    }
  }, [isMobile, pickerRef]);

  return (
    <FormControl minW={minWidth} maxW={maxWidth}>
      {displayLabel && <InputFormLabel fieldName={fieldName} fieldLabel={fieldLabel} />}

      <DatePicker
        ref={pickerRef}
        name={fieldName}
        locale={i18n.locale}
        required={isRequired}
        placeholderText={fieldPlaceholder && i18n._(fieldPlaceholder)}
        customInput={<ChakraDateInput />}
        withPortal={isMobile}
        portalId="root"
        todayButton={displayToday ? <Trans>Today</Trans> : undefined}
        isClearable
        autoComplete="off"
        showDisabledMonthNavigation
        minDate={new Date()}
        disabledKeyboardNavigation
        dateFormat={dateFormat}
        shouldCloseOnSelect
        {...props}
      >
        {props.children}
      </DatePicker>
    </FormControl>
  );
};

export default forwardRef(DatepickerCommon);

export const closeDatePicker = pickerRef => pickerRef.current.setOpen(false);
export const closeReopenDatePicker = pickerRef => {
  pickerRef.current.setOpen(false);
  setTimeout(() => {
    pickerRef.current.setOpen(true);
  }, 50);
};
