import { useMemo } from 'react';

import useReadFilters from './useReadFilters';

const useReadFilterValue = filter => {
  const { debouncedFilters, getFilterValue } = useReadFilters();
  const filterValue = useMemo(() => getFilterValue(filter.fieldName, filter.type, filter.default), [JSON.stringify(debouncedFilters)]);
  return filterValue;
};

export default useReadFilterValue;
